.errorBlock {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.errorBlock__img {
  position: relative;
  width: 300px;
  height: auto;
  margin-right: 20px;
  background-color: #f0f0f0;
}

.errorBlock__desk {
  flex: 1;
}

// .errorBlock__title {
//   font-size: 32px;
//   font-weight: bold;
//   line-height: 1.3;
//   color: #000;
//   padding: 0;
//   margin-bottom: 10px;
// }

.errorBlock__img:before {
  content: '';
  position: absolute;
  width: 176px;
  height: 114px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url('/icons/book-404.svg') no-repeat 0 0;
  background-size: cover;
}

.errorBlock__txt {
  font-size: 16px;
  margin-bottom: 40px;
}

.errorBlock__btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  padding: 12px 32px 12px 50px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  background: #f0f0f0;
  color: #575757;
  &:hover {
    background: #e1e1e1;
    color: #000;
  }
  &:before {
    position: absolute;
    content: '';
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 13px;
    background-size: cover;
    background: url('/icons/arrow-left-black.svg') no-repeat 0 0;
    opacity: 0.65;
    transition: opacity 0.3s ease;
  }
}

@media screen and (max-width: 1300px) {
  .errorBlock__img {
    width: 250px;
  }

  .errorBlock__img:before {
    width: 168px;
    height: 110px;
  }
}

@media screen and (max-width: 820px) {
  // .errorBlock__title {
  //   font-size: 24px;
  // }
  .errorBlock__img {
    width: 220px;
  }
  .errorBlock__img:before {
    width: 152px;
    height: 98px;
  }
}

@media screen and (max-width: 530px) {
  // .errorBlock__title {
  //   font-size: 20px;
  // }
  .errorBlock {
    margin-bottom: 40px;
  }

  .errorBlock__img {
    width: 100%;
    height: 125px;
    margin: 0 0 20px 0;
  }
  .errorBlock__txt {
    margin-bottom: 20px;
  }
}
